import * as React from "react";
import Layout from "../../components/Layout";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { graphql, PageProps } from "gatsby";
import SEO from "../../components/Seo";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import PageTitle from "../../components/PageTitle";
import RichText from "../../components/RichText";
import StructuredData from "../../components/StructuredData";
import {
  ImageInterface,
  LocalizedContextInterface,
  MultiLineHeadline,
  PortableText,
  SanityColorList,
  Seo
} from "../../types/SanityTypes";
import { LocalisedPageContext } from "../../types/PageTypes";
import "./styles.scss";

type AboutUsQueryProps = {
  sanityAboutUs: {
    banner: {
      headline: MultiLineHeadline;
      primaryColor: SanityColorList;
      _rawShortIntro: PortableText;
      _rawHeroImage: ImageInterface;
    };
    subHeadline: string;
    _rawTextBody: PortableText;
    seo: Seo;
    slug: {
      current: string;
    };
  };
} & LocalizedContextInterface;

type AboutUsContext = LocalisedPageContext;

export const query = graphql`
  query AboutUs($_id: String, $language: String, $market: String) {
    sanityAboutUs(_id: { eq: $_id }) {
      banner {
        headline {
          primaryText
          secondaryText
          tertiaryText
        }
        primaryColor {
          value
          title
        }
        _rawShortIntro(resolveReferences: { maxDepth: 8 })
        _rawHeroImage(resolveReferences: { maxDepth: 8 })
      }
      seo {
        metaTitle
        metaDescription
        jsonld
      }
      slug {
        current
      }
      subHeadline
      _rawTextBody
    }
    ...LocalizedContext
  }
`;

const AboutUs = (props: PageProps<AboutUsQueryProps, AboutUsContext>) => {
  const language = props.pageContext.language;
  const aboutUs = props.data.sanityAboutUs;
  const { htmlLang } = useSiteMetadata(language);

  // This is to make sure banner works of internationalisation
  const lineOne =
    aboutUs.banner.headline.primaryText.length <= 5
      ? aboutUs.banner.headline.primaryText
      : aboutUs.banner.headline.primaryText + " ";
  const lineTwo = aboutUs.banner.headline.secondaryText === null ? " " : aboutUs.banner.headline.secondaryText + " ";
  const lineThree = aboutUs.banner.headline.tertiaryText === null ? " " : aboutUs.banner.headline.tertiaryText + " ";

  // Subheadline will always be split in half
  const splitHeading = (text: string) => {
    const textArray = text.toUpperCase().split(" ");
    const middle = Math.floor(textArray.length / 2);
    const split1: string[] = [];
    const split2: string[] = [];
    textArray.map((item: string, i: number) => {
      i < middle ? split1.push(item) : split2.push(item);
    });

    return [split1.join(" ") + " ", split2.join(" ")];
  };

  const renderSubHeading = () => {
    const splitSubHeading = splitHeading(aboutUs.subHeadline);

    return (
      <h2>
        {splitSubHeading[0]} <span className="sub-heading">{splitSubHeading[1]}</span>
      </h2>
    );
  };

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={aboutUs.seo.metaTitle}
      pageType={"about"}
    >
      <StructuredData
        type={"AboutPage"}
        name={aboutUs.seo.metaTitle}
        description={aboutUs.seo.metaDescription}
        data={aboutUs.banner._rawHeroImage}
        customData={aboutUs.seo.jsonld}
        language={htmlLang}
        slug={`/${aboutUs.slug.current}/`}
      />
      <SEO
        title={aboutUs.seo.metaTitle}
        description={aboutUs.seo.metaDescription}
        imageUrl={aboutUs.banner._rawHeroImage.asset.url}
        imageAlt={aboutUs.banner._rawHeroImage.alt}
      />
      <div className="page_about-us">
        <Container fluid>
          <Row className="header-type-1">
            <Col>
              <PageTitle
                title={[lineOne, lineTwo, lineThree]}
                image={aboutUs.banner._rawHeroImage}
                color={aboutUs.banner.primaryColor.title}
              />
            </Col>
          </Row>
          <div className="our-commitment">{renderSubHeading()}</div>
          <div className="our-stories">
            <RichText data={aboutUs._rawTextBody} />
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default AboutUs;
